import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import get from 'lodash/get';
import cls from 'classnames/bind';

import addScpParameterToUrl from '@ott/utility-scp-logic';
import { withLayoutContext } from '@ott/layout-context';
import { getAvailableTests } from '@ott/ab-test';
import { l10n, lang } from '@ott/l10n';
import Picture from '../Picture';

import Button from '@ott/button';
import { Container, Row, Col } from '@ott/grid';

import RailwaysSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/RailwaysSearchForm';
import BusSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/BusSearchForm';
import FlightsSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/FlightsSearchForm';
import HotelsSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/HotelsSearchForm';
import ActivitiesSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/ActivitiesSearchForm';
import CarsSearchForm from 'src/components/organisms/SearchFormSwitcher/blocks/CarsSearchForm';
import HotelsBadge from 'src/components/organisms/HotelsBadge';
import { isOttBrand } from 'src/helpers/ottBrand';

import BadgeNewIcon from 'src/components/icons/BadgeNewIcon';

import { urls } from 'src/constants/common';
import metrics from 'src/utility/metrics';

import styles from './SearchFormSwitcher.scss';

const B2B_ARROW_ICON = 'https://static.onetwotrip.com/images/index/b2b_arrow.svg';
const PAGE_404 = '404';

@withLayoutContext
@connect((state) => ({
  infoStatus: state.common.auth.infoStatus,
}))
class SearchFormSwitcher extends React.Component {
  state = {};
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    activeForm: PropTypes.string.isRequired,
    formMenu: PropTypes.array.isRequired,
    className: PropTypes.string,
    pageInfo: PropTypes.shape({
      deviceInfo: PropTypes.shape({
        isDesktop: PropTypes.bool,
        isMobile: PropTypes.bool,
        isTablet: PropTypes.bool,
        isTouchDevice: PropTypes.bool,
      }),
    }),
    config: PropTypes.object,
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      b2bLink: props.formMenu.find((item) => item.key === 'b2b')?.link.href,
      page404: isOttBrand() && props.config.product === '404',
    };
  }

  componentDidUpdate() {
    const {
      location: { search },
    } = this.props;
    const { b2bLink } = this.state;

    if (search.includes('scp') && b2bLink && !b2bLink.includes('scp')) {
      this.setState({ b2bLink: addScpParameterToUrl(b2bLink) });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  renderFormNode = () => {
    const { activeForm, pageInfo, config } = this.props;

    const { page404 } = this.state;

    const commonProps = {
      urls,
      showBusinessTripCheckbox: config.searchForm?.showBusinessTripCheckbox ?? false,
    };

    if (page404) {
      return null;
    }

    switch (activeForm) {
      case 'avia':
        return (
          <FlightsSearchForm
            className={styles.form}
            {...commonProps}
            deviceInfo={pageInfo.deviceInfo}
          />
        );
      case 'poezda':
        return <RailwaysSearchForm {...commonProps} />;
      case 'activities':
        return <ActivitiesSearchForm {...commonProps} />;
      case 'bus':
        return <BusSearchForm {...commonProps} />;
      case 'hotels':
        return <HotelsSearchForm {...commonProps} />;
      case 'cars':
        return <CarsSearchForm {...commonProps} />;
    }
  };

  renderNav() {
    const {
      formMenu = [],
      webView,
      config: { product },
      activeForm,
    } = this.props;

    const { b2bLink } = this.state;
    const { page404 } = this.state;

    if (webView.isWebView || page404) {
      return null;
    }

    return formMenu.map((navItem) => {
      if (!navItem.link) {
        return null;
      }

      const {
        link: {
          isExternal,
          withTripcoins,
          withArrowIcon,
          isNew,
          target,
          event,
          href,
          title,
          ...restAttribute
        },
        key,
      } = navItem;

      const navItemBody = (
        <>
          {title}
          {isNew && <BadgeNewIcon className={styles.badge} />}
          {withTripcoins && <HotelsBadge className={styles.hotelsBadge} />}
          {withArrowIcon && <img className={styles.badge} src={B2B_ARROW_ICON} alt="b2b-arrow" />}
        </>
      );

      if (isExternal) {
        return (
          <a
            className={cls(styles.navLink, {
              [styles.navLinkSeparated]: key === 'b2b',
            })}
            key={key}
            {...restAttribute}
            href={key === 'b2b' ? b2bLink : href}
            data-locator={`navigationLink ${key}`}
            target={target || ''}
            onClick={() => {
              if (event) {
                metrics(event, {
                  page: `index_${product}`,
                });
              }
            }}
          >
            {navItemBody}
          </a>
        );
      }

      if (navItem.component === activeForm) {
        restAttribute.isActive = () => {
          return true;
        };
      }

      return (
        <NavLink
          exact={true}
          key={key}
          to={{
            pathname: href,
          }}
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
          {...restAttribute}
        >
          {navItemBody}
        </NavLink>
      );
    });
  }

  renderIndexCover() {
    const { config, isDesktop } = this.props;
    const { partner } = config;

    const desktopSource = get(config, 'media.formBg');
    const mobileSource = get(config, 'media.formBgMobile');

    return (
      <div className={styles.coverWrapper}>
        <Picture
          source={isDesktop ? desktopSource : mobileSource}
          className={styles.cover}
          alt="index-cover"
        />
        <div className={cls({ [styles.coverFilter]: partner })} />
      </div>
    );
  }

  renderFormCaption() {
    const {
      config: { partner },
      formCaption,
      ABTestFormCaption,
      activeForm,
    } = this.props;

    let title = formCaption || '';

    const availableABTests = getAvailableTests() || [];

    if (ABTestFormCaption && availableABTests.length > 0) {
      const abTestName = Object.keys(ABTestFormCaption)[0]; // r36_a
      const abTestKey = abTestName.slice(0, abTestName.indexOf('_')); // r36
      const currentCookieForABTest = availableABTests.find((abTest) => abTest.includes(abTestKey)); // r36_b includes r36
      title = ABTestFormCaption[currentCookieForABTest] || title;
    }

    return (
      <div className={styles.caption}>
        {title}
        {activeForm === 'hotels' && lang === 'ru' && !partner && (
          <div className={styles.percentsBadge}>
            <span>до 30%</span>
          </div>
        )}
      </div>
    );
  }

  renderTitle() {
    const { config } = this.props;

    if (config.product === PAGE_404) {
      return (
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{l10n('page404.title')}</h2>
          <div className={styles.subtitle}>{l10n('page404.subtitle')}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderButton() {
    const {
      config: { product },
    } = this.props;

    if (product === PAGE_404) {
      return (
        <div className={styles.indexButtonWrapper}>
          <Button type="button" className={styles.indexButton} href={`/${lang}/`}>
            <span>{l10n('page404.component.mainButton')}</span>
          </Button>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { className, activeForm } = this.props;
    const formNode = this.renderFormNode();
    const indexCover = this.renderIndexCover();
    const navigationNode = this.renderNav();
    const captionNode = this.renderFormCaption();
    const titleNode = this.renderTitle();
    const buttonNode = this.renderButton();

    return (
      <div
        id="search-form-switcher"
        className={cls(
          styles.wrapper,
          styles[`wrapper--${activeForm}`],
          className,
          titleNode && styles['wrapper--withTitle']
        )}
      >
        {indexCover}
        <Container className={cls(styles.container, styles[`container--${activeForm}`])}>
          <Row>
            <Col desktop={12} mobile={4}>
              {titleNode}
              <div className={styles.nav} data-locator="navigationLinks">
                {navigationNode}
              </div>
              {captionNode}
              {formNode}
              {buttonNode}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default hot(module)(SearchFormSwitcher);
