import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hot } from 'react-hot-loader';
import { scroller } from 'react-scroll';
import { isBefore } from 'date-fns';
import { lang } from '@ott/l10n';
import Button from '@ott/button';
import LoadingDots from '@ott/loading-dots';
import Picture from '../Picture';
import { Container } from '@ott/grid';
import {
  helpers as virtualCardHelpers,
  hooks as virtualCardHooks,
  selectors as virtualCardSelectors,
} from '@ott/shared-virtual-card-logic';
import { useLayoutContext } from '@ott/layout-context';
import { isOttBrand } from '../../../helpers/ottBrand';
import { DefaultBannerData, PromotionBannerData } from './types';
// @ts-ignore
import styles from './VirtualCardBanner.scss';

const {
  virtualCardFlow: { useVirtualCardFlow },
} = virtualCardHooks;

type Props = {
  id: string;
  defaultData: DefaultBannerData;
  promotionData?: PromotionBannerData;
  className?: string;
};

const VirtualCardBanner = (props: Props) => {
  const { id, defaultData, promotionData, className }: Props = props;
  const {
    buttonCaptions: { release, upgrade, identified },
  } = defaultData;
  const {
    data: { auth: isAuthorized = false, cards = [] } = {},
    infoStatus: { loading: isAuthLoading },
  } = useSelector((state) => state.common.auth);
  const { data: virtualCardData, loading: isVirtualCardLoading } = useSelector(
    virtualCardSelectors.getCardInfo
  );
  const isIdentified = useSelector(virtualCardSelectors.selectIsIdentified);
  const isIdentificationInProgress = useSelector(
    virtualCardSelectors.selectIsIdentificationInProgress
  );
  const hasVirtualCard =
    virtualCardHelpers.hasVirtualCard(cards) && Boolean(virtualCardData?.phone);
  const { isDesktop } = useLayoutContext();
  const { openReleaseModal, openUpgradeModal } = useVirtualCardFlow();

  const buttonCaption = useMemo(() => {
    if (isAuthorized && hasVirtualCard && (isIdentified || isIdentificationInProgress)) {
      return identified;
    }

    if (isAuthorized && hasVirtualCard && !isIdentified && !isIdentificationInProgress) {
      return upgrade;
    }

    return release;
  }, [
    isAuthorized,
    hasVirtualCard,
    isIdentified,
    release,
    upgrade,
    identified,
    isIdentificationInProgress,
  ]);

  const scrollToSearch = () => {
    scroller.scrollTo('search-form-switcher', {
      duration: 500,
      delay: 100,
      smooth: 'easeInOutQuart',
    });
  };

  const handleButtonClick = () => {
    if (isAuthorized && hasVirtualCard && (isIdentified || isIdentificationInProgress)) {
      scrollToSearch();
    }

    if (isAuthorized && hasVirtualCard && !isIdentified && !isIdentificationInProgress) {
      openUpgradeModal();
    }

    if (!isAuthorized || (isAuthorized && !hasVirtualCard)) {
      openReleaseModal({ withQuickAuthorization: false });
    }
  };

  const currentDate = new Date();

  if (lang !== 'ru' || __WHITE_LABEL__ || isOttBrand()) {
    return null;
  }

  if (promotionData && isBefore(currentDate, new Date(promotionData.endOfPromotion))) {
    return (
      <Container id={id} className={className}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>{promotionData.title}</div>
            <div className={styles.description}>{promotionData.description}</div>
            <Button
              className={styles.button}
              childrenClassNames={{ children: styles.buttonContent }}
              onClick={scrollToSearch}
            >
              {promotionData.promotionButtonCaption}
            </Button>
          </div>
          <Picture
            source={promotionData.images.desktop}
            className={styles.image}
            alt="virtual-card"
          />
        </div>
      </Container>
    );
  }

  return (
    <Container id={id} className={className}>
      <div className={styles.wrapper} data-locator="virtual-card-banner">
        <div className={styles.content}>
          <div className={styles.title} data-locator="virtual-card-banner-title">
            {defaultData.title}
          </div>
          <div className={styles.description} data-locator="virtual-card-banner-description">
            {defaultData.description}
          </div>
          {defaultData.note && (
            <div className={styles.note} data-locator="virtual-card-banner-tripcoins-ratio">
              {defaultData.note}
            </div>
          )}
          <Button
            className={styles.button}
            childrenClassNames={{ children: styles.buttonContent }}
            onClick={handleButtonClick}
            isDisabled={isVirtualCardLoading}
            specificLocators="virtual-card-banner-release"
          >
            {isAuthLoading || isVirtualCardLoading ? <LoadingDots isWhite={true} /> : buttonCaption}
          </Button>
        </div>
        <div className={styles.creative}>
          <Picture
            source={isDesktop ? defaultData.images.desktop : defaultData.images.mobile!}
            className={styles.image}
            specificLocator="virtual-card-banner-icon"
            alt="virtual-card"
          />
        </div>
      </div>
    </Container>
  );
};

export default hot(module)(VirtualCardBanner);
