import superagent from '@ott/superagent';
import T from '../types';

export default ({ phoneNumber, source }) => {
  return {
    promise: async () => {
      const res = await superagent
        .get('/_api/landings/sendApplicationLink')
        .query({ phoneNumber, source })
        .type('form')
        .withCredentials();

      if (res && res.body && res.body.success) {
        return res.body.result;
      } else {
        return Promise.reject(res.body);
      }
    },
    types: [
      T.SEND_MOBILE_APP_LINK_SMS,
      T.SEND_MOBILE_APP_LINK_SMS_SUCCESS,
      T.SEND_MOBILE_APP_LINK_SMS_FAIL,
    ],
  };
};
