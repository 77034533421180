import { lang } from '@ott/l10n';

let hostname = '';

if (__DEV__) {
  hostname = 'https://www.onetwotrip.com';
}

export const urls = {
  mobile: {
    profile: `/${lang}/p/`,
    ticket: `/${lang}/ticket/`,
    auth: `//m.onetwotrip.com/${lang}/auth/`,
    achievements: `/${lang}/loyalty/achievements/`,
    travelexpert: `/${lang}/loyalty/travelexpert/`,
  },

  flightsSearch: `${hostname}/${lang}/f/search`,
  railwaysSearch: `${hostname}/${lang}/poezda`,
  toursSearch: `${hostname}/${lang}/tours/search`,
  toursHotel: `${hostname}/${lang}/tours/hotel`,
  busSearch: `${hostname}/${lang}/bus`,
  hotelsSearch: `${hostname}/${lang}/hotels`,
  carsSearch: `${hostname}/${lang}/cars`,

  suggestUrls: {
    hotels: '/_hotels/api',
  },
};

export const statuses = {
  classic: 'classic',
  premium: 'premium',
  pro: 'pro',
  exclusive: 'exclusive',
  guest: 'guest',
};

export const DEFAULT_PRODUCT = 'avia';

export const PRODUCTS_LIST = [
  'avia',
  'poezda',
  'tours',
  'hotels',
  'cars',
  'bus',
  'activities',
  'explore',
];

export const EMPTY_STRING = '';

export const S3_URL = 'https://static.onetwotrip.com/images';
